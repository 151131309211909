.form-container{
    background-color: #eee;
    border-radius: 10px;
    max-height: 60vh;
    width: 50vw;
    padding: 5vh 5vw;
    overflow: auto;
}

.customer-form-container{
    border-radius: 10px;
    max-height: 60vh;
    width: 50vw;
    padding: 5vh 5vw;
    overflow: auto;
}

.file-name-form{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.file-name-form > p{
    width: 80%;
    margin: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
}