.half-a-border-on-top:after {
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 5px;
    height: 15.5px;
    background-color: white;
    position: absolute;
    right: -3px;
    top: 0;
}

.half-a-border-on-bottom:after {
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 5px;
    height: 20.5px;
    background-color: white;
    position: absolute;
    right: -3px;
    bottom: 0;
}