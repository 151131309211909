.scrollable{
    height: auto !important;
    width: auto !important;
    min-width: inherit !important;
    margin: auto;
    margin-bottom: 20px;
}

.file-name-form{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}
.file-name-form > p{
    width: 80%;
    margin: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
}


.tw-grid-container{
    width: 100% !important;
    margin: auto !important;
}

.warrant-form-btn{
    z-index: 17; 
    width: 5vw;
}

.warrant-form-btn-container{
    position: absolute;
    bottom: 20px;
}

.twItems-scrollable {
    max-height: 120px;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.calendar-vehicle-card-text {
    overflow: hidden;
    height: 18px;
    min-width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.graph-driver-card-container {
    height: 40px;
    display: flex;
}

.graph-driver-card-text {
    height: 40px;
    width: calc(100% - 50px);
}

@media only screen and (max-width: 1200px) {
    .graph-driver-card-container {
        width: 25vw;
    }
}

@media only screen and (min-width: 1200px) {
    .graph-driver-card-container {
        width: 12vw;
    }
}