/* CARDS */
.card {
    background-color: #fff;
    color: #111;
    height: 100%;
    width: 200px;
    margin: 0px;
}

.calendar-card {
    background-color: #fff;
    padding: 1px !important;
    color: #111;
    height: 100% !important;
    width: 110px !important;
    margin: auto !important;
}

.grid-container{
    width: 70vw !important;
    margin: auto !important;
    margin-top: 40px !important;
    justify-content: space-between;
}

.catalogue-card-text{
    word-wrap: break-word;
    word-break: normal;
}