.login-form{
    background-color: rgba(0, 0, 0, 0.85); 
    z-index: 600; 
    position: absolute;
    width: 100vw;
    height: 100vh;
}
  
.logout-modal{
    padding: 15px;
    width: 250px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.user-icon{
    width: 100%;
    text-align: center;
    margin-bottom: 0px;
}

.info {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
}

.user-name{
    font-size: 20px;
}

.user-name > p, .phonenumber > p {
    margin: 0px;
}

.phonenumber{
    color: rgb(133, 133, 124);
}

hr {
    opacity: 0.3;
}

.buttons {
    font-size: 15px !important;
    width: 100%;
}

.buttons > Button {
    justify-content: flex-start !important;
}

.change-pass-close-btn{
    position: absolute !important;
    top: 85px;
    right: -15px;
    height: 40px !important;
    width: 40px !important;
}