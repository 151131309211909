.shipment-loading-date-picker input {
    padding: 5px;
    width: 170px;
}

.shipment-loading-element {
    height: 110px;
    transition: background-color .1s ease-in-out;
    transition: height .5s ease-in-out;
}

.shipment-loading-element:hover {
    background-color: rgba(213, 225, 243, 0.514);
    height: 200px;
}