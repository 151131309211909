.table-profile-card {
    width: 500px;
}

.table-close-btn {
    position: fixed !important;
    top: 105px !important;
    right: 50vw !important;
    margin-right: -265px !important;
    height: 40px !important;
    width: 40px !important;
}

.table-modalStyle{
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    margin-top: 100px !important;
    justify-content: center;
}

.feed-table-element {
    background-color: white;
    border: 1px solid rgb(206, 206, 206);
    border-bottom: none;
    padding: 7px;
    padding-left: 30px;
}

.feed-table-element:hover{
    background-color: rgb(235, 235, 235);
}

.feed-table-header-footer {
    background-color: white;
    border: 1px solid rgb(206, 206, 206);
    padding: 7px;
    padding-left: 30px;
}