.profile{
    margin: auto !important;
    display: flex;
}

.details-element{
    position: absolute;
    top: 0px;
    right: 0px;
    transition: ease all .5s;
    z-index: 8;
}

.feed-element{
    width: 70vw !important;
    margin: auto;
}

.vehicle-feed-selector{
    position: fixed;
    z-index: 6;
    width: 500px;
    margin: auto;
    margin-left: calc(35vw - 250px);
}

.feed{
    margin: auto;
    margin-top: 80px;
    width: 100%;
}

.feed-tab{
    min-width: 100px !important;
    width: 100px !important;
}

.important-docs-menu{
    max-height: 500px;
    overflow-y: auto;
}

.important-docs-menu-item{
    width: 150px;
    word-wrap: initial;
}

.important-docs-menu-item > p {
    width: 150px;
}