.link, .link:link, .link:visited, .link:hover, .link:active, .link:focus {
    text-decoration: none;
    color: inherit;
}

.custom-form-main {
    position: relative !important;
    width: 80vw !important;
    max-width: 800px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    top: 10vh !important;
    height: 80vh !important;
}

.custom-form-paper {
    padding: 16px !important;
    height: 100% !important;
    width: 100% !important;
}

.custom-stepper-form-main {
    position: relative !important;
    width: 800px;
    margin-left: auto !important;
    margin-right: auto !important;
    top: 10vh !important;
    height: 80vh !important;
}

.custom-stepper-form-paper {
    padding: 16px !important;
    min-height: 500px !important;
    max-height: 80vh !important;
    min-width: 50vw !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.custom-form-close {
    position: absolute !important;
    right: 10px !important;
    top: 0 !important;
    font-size: 30px !important;
    font-family: 'Courier New' !important;
    cursor: pointer !important;
}

.custom-form-buttons {
    display: flex !important;
    justify-content: flex-end !important; 
}

.custom-form-button {
    margin-top: 24px !important;
    margin-left: 8px !important;
}
