.no-data-container{
    width: 100vw;
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.no-data-img{
    width: 30vw !important;
    height: auto;
}

.no-data-img > img {
    width: 30vw !important;
    height: auto;
    opacity: 0.2;
}

.no-data-text{
    font-size: 30px;
    text-align: center;
    color: rgba(143, 143, 143, 0.8);
}