.loader-container{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
  }
  
  .loader{
    width: 40% !important;
    height: 40% !important;
    margin: auto;
  }