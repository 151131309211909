.filter-bar-container {
    background-color: #051628;
    height: 60px;
    margin-top: -1px;
    width: 100%;
    z-index: 14px;
    color: white;
}

.filter-bar-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    height: 30px;
    margin: auto;
    position: relative;
    top: 15px;
    right: 10px;
}

.filter-bar-flex-container .Mui-disabled {
    background-color: #aaa !important;
}

.filter-bar-flex-container .MuiOutlinedInput-notchedOutline {
    color: white !important;
    border-color: white !important;
}
  
.filter-bar-flex-container .Mui-focused .MuiOutlinedInput-notchedOutline {
    color: white !important;
    border-color: white !important;
}

.filter-bar-flex-container .MuiSvgIcon-root {
    fill: white !important;
}

.filter-bar-date-picker input {
    color: white;
    padding: 5px;
    width: 170px;
}