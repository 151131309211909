.notification-menu-item-container {
    width: 300px;
    word-wrap: initial;
    position: relative;
}

.notification-menu-item-container > p {
    width: 300px;
}

.notification-description {
    max-height: 80px;
    overflow: hidden;
    line-height: 20px !important;
}

.notification-form-container{
    background-color: #fff;
    border-radius: 10px;
    width: 30vw !important;
    max-height: 60vh;
    position: absolute;
    padding: 5vh 5vw;
    overflow: auto;
}

.notification-modal{
    padding-top: 10vh;
    margin-left: 35vw;
    width: 100vw;
    height: 100vh;
}

.notification-close-btn{
    position: fixed !important;
    right: 25vw;
    top: 8.5vh;
    height: 50px !important;
    width: 50px !important;
}

.notification-list-item{
    width: inherit;
    word-wrap: initial;
    white-space: normal;
}

.notification-list-item > p{
    width: inherit;
    word-wrap: initial;
    white-space: normal;
}