.nav-btn-list-root {
    width: 100% !important;
    max-width: 360px !important;
}

.nav-btn-list-nested {
    padding-left: 32px !important;
}

.nav-btn-list-nonActive {
    border-left: 3.5px solid transparent !important;
}

.nav-btn-list-active {
    border-left: 3.5px solid rgba(0, 132, 255, 0.8) !important;
    background-color: rgba(0, 132, 255, 0.3) !important;
}

.nav-btn-list-activeMain {
    border-left: 3.5px solid rgba(0, 132, 255, 0.8) !important;
    background-color: rgba(0, 132, 255, 0.5) !important;
}

.nav-btn-list-bold {
    font-weight: bold !important;
    font-size: 25px !important;
}