.carousel{
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    min-height: 480px;
    max-height: 60vh;
    min-width: 300px;
    width: 50vw;
    padding: 5vh 5vw;
    margin-top: 100px;
}
.carousel-container{
    display: flex; 
    justify-content: center;
}

.carousel-img{
    height: 400px;
    margin:auto;
}

.carousel-img > img{
    max-height: 100%;
    width: 100%;
}

.file-name-carousel {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.file-name-carousel > p{
    width: 80%;
}

.file-name-link{
    text-decoration: none;
    color:rgba(0, 0, 0, 0.8);
    position: relative;
    height: 100%;
    width: 100%;
}