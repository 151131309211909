.event-description {
    max-height: 80px;
    overflow: hidden;
    line-height: 20px !important;
}

.event-menu-item-container {
    width: 300px;
    word-wrap: initial;
    position: relative;
}