.directions-map-container {
    display: flex;
    height: calc(100vh - 320px);
}

.directions-map-controls {
    width: 20%;
    padding: 1rem;
    background: #14161a;
    color: white;
}

.directions-map-controls input {
    border: none;
}

.directions-map-map-container {
    width: 100%;
    height: inherit;
    border-radius: 13px !important;
    border: 1px solid #3277d5;
}

/* .gm-style-iw-c {
 } */