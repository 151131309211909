.tw-report-container{
    background-color: #e1e1e1;
    margin: auto;
    padding: 0px 10px 10px 10px;
}

.tw-report-scroll{
    overflow-y: scroll;
    width: 100%;
    height: 85vh;
    margin: auto !important;
    margin-top: 10px !important;
}

.tw-report-content-container{
    width: 100%;
    height: 100%;
}

.tw-report-page{
    width: 100%;
    height: 100%;
    padding: 10%;
    padding-top: 3%;
    position: relative !important;
    background-color: #ffffff;
}

.tw-report-content{
    width: 100%;
    height: 100%;
    padding: 10%;
    position: relative !important;
    background-color: #ffffff;
}

.tw-report-content > p {
    font-size: 2vw;
}

.tw-report-content > footer {
    font-size: 2vw;
}

.tw-report-content > span {
    font-size: 2vw;
}

.tw-report-info{
    display: inline;
}