.calendar-shipment-card-text {
    overflow: hidden;
    height: 18px;
    min-width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
}

.calendar-shipment-text-white {
    color: white;
    font-size: 15px;
}

.calendar-shipment-text-gray {
    color: rgba(256, 256, 256, 0.7);
}

.calendar-shipment-element {
    height: 60px;
    width: 100%;
    max-width: 200px;
    padding: 5px;
    background-color: #1976d2;
    border-radius: 5px;
    cursor: pointer;
}

.calendar-shipment-element-animated {
	animation: pulse 2s infinite;
	box-shadow: 0 0 0 0 rgb(97, 107, 243);
}

.calendar-shipment-menu-item-container {
    width: 300px;
    word-wrap: initial;
}

@keyframes pulse {
    0% {
		transform: scale(1.0);
		box-shadow: 0 0 0 0 rgba(97, 107, 243, 0);
	}

	50% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(97, 107, 243, 0.7);
	}

	100% {
		transform: scale(1.0);
		box-shadow: 0 0 0 7px rgba(97, 107, 243, 0);
	}
}