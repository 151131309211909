.additions-btn{
    position: fixed !important;
    right: 50px;
    bottom: 50px;
    height: 55px !important;
    width: 55px !important;
}

.add-record-btns {
    position: fixed !important;
    right: 50px;
    bottom: 50px;
}

.edit-btn{
    position: fixed !important;
    right: 50px;
    bottom: 150px;
    height: 60px !important;
    width: 60px !important;
}

.modalStyle{
    padding: 0px !important;
    margin: 0px !important;
}

.close-btn{
    position: absolute;
    top: 85px !important;
    right: 25px !important;
    margin-right: -15px !important;
    height: 40px !important;
    width: 40px !important;
}

.customer-close-btn{
    display: none !important;
}

.travel-warrant-btn{
    position: fixed !important;
    right: 50px;
    bottom: 150px;
    height: 80px !important;
    width: 80px !important;
    padding: 15px;
}