.tw-container{
    background-color: #fff;
    position: relative;
    overflow: auto;
    margin-bottom: 50px;
}

.transport-warrant-selector{
    z-index: 1; 
    width: 500px;
}

.tw-container p {
    font-size: 16px;
    
}

.tw-container p span {
    font-weight: bold;
}