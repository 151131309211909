.tw-form-btn{
    position: fixed !important;
    right: 50px !important;
    top: 100px !important;
    z-index: 18;
}

.calendar-popover{
    background-color: #1F2426;
    color: #f1f1f1;
    padding: 15px;
    width: 300px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.tw-report-modal {
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    justify-content: center;
}

.calendar-filter-element {
    position: absolute;
    top: 5px;
    right: 250px;
    z-index: 10;
    width: 300px;
}