.image-album-container{
    width: 100%;
    height: 100%;
    position: relative;
}

.album-image{
    width: inherit;
    height: inherit;
    object-fit: cover;
    cursor: pointer;
}

.profile-card{
    margin-top: 20px;
    cursor: pointer;
}

.profile-card-content {
    cursor: pointer;
}

.profile-card-content-hovered {
    background-color: rgba(243, 243, 243, 0.6);
}

.profile-card-modal-style{
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    justify-content: center;
    position: relative;
}

.last-image{
    display: flex;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 100%;
    justify-content: center;
    z-index: 5;
    cursor: pointer;
}

.last-image > p {
    font-size: 40px;
    color: rgb(204, 204, 204);
}

.last-image-disabled{
    display: none;
}

.file-name {
    display: flex;
    position: absolute;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.file-name-p{
    width: 90px;
    margin: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    font-size: 10px;
}

.file-icon {
    width: inherit;
    height: inherit;
    margin: auto;
}

.file-icon-container {
    width: 100%;
    height: 100%;
    display: flex;
}