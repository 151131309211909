.viber-request-modal{
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    margin-top: 100px !important;
    justify-content: center !important;
    text-align: center;
}

.viber-modal-container {
    background-color: #eee;
    border-radius: 10px;
    max-height: 250px;
    width: 400px;
    padding: 50px;
    overflow: none;
}