.card-img {
    height: 100px !important;
    width: 100% !important;
    object-fit: cover;
}
  
.calendar-card-img {
    height: 40px !important;
    width: 50px !important;
    object-fit: cover;
}

.card-img-loader{
    margin: auto;
    height: 100px;
    width: 100px;
}

.calendar-card-img-loader {
    margin: auto;
    height: 50px !important;
    width: 50px !important;
}