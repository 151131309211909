
@font-face{
    font-family: 'Roboto-Regular';
    src: url('../../../General/assets/fonts/Roboto-Regular.ttf');
}

.img-div{
    height: auto;
    width: 100%;
    margin: auto;
    padding: 5px;
}

.img-div > img{
    width: inherit;
    height: inherit;
    object-fit: contain; 
    margin: auto !important;
}
  
.details{
    font-family: 'Roboto-regular';
    font-size: 16px;
    width: 100%;
    height: 100%;
    padding: 10px 30px;
    overflow-y: auto;
    overflow-x: hidden;
}

.details .naming{
    width: 100px !important;
    color: rgba(0, 0, 0, 0.603);
    display: inline;
}
  
.details .info{
    float: right;
    font-weight: bold;
    width: 100px !important;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    display: inline;
    margin: 0px;
    text-align: end;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Arrow */

.arrow{
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.arrow > span {
    top: 1rem;
    left: 1rem;
    position: absolute;
    width: .75rem;
    height: .1rem;
    background-color: #303030;
    display: inline-block;
    transition: all .2s ease;
}

.first-span{
    top: .75rem !important;
    left: .5rem !important;
    transform: rotate(45deg);
}

.second-span {
    top: .75rem !important;
    transform: rotate(-45deg);
}

.active.first-span {
    transform: rotate(-45deg);
}

.active.second-span {
    transform: rotate(45deg);
}