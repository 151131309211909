.google-maps-search{
    position: absolute;
    top: 0px;
    left: 0%;
    width: calc(100% - 60px);
}

.google-maps-item{
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #212121;
}