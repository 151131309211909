.scrollable-feed{
    height: 70vh;
    width: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.feed-search {
    width: 300px;
    height: 300px;
    padding: 20px;
    z-index: 10;
    background-color: #f7f7f7;
    border: 1px solid black;
    border-radius: 5px;
}

.feed-expense-search {
    height: 380px;
}

.feed-tw-search {
    height: 220px;
}

.outter {
    position: relative; 
    width: 100%;
    height: calc(100vh - 150px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.inner{
    position: absolute;
    width: 100%;
    height: calc(100vh - 150px);
}