::-webkit-scrollbar {
    height: 6px; 
}

.shipments-underbar-container {
    transition: width .3s ;
    height: 81px;
    background-color: #051628;
    overflow-x: auto;
}

.shipments-underbar-flex-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.shipments-underbar-container-full-width {
    width: 100%;
}

.shipments-underbar-container-70 {
    width: calc(100% - 500px);
}